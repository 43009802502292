<template>
  <v-app style="padding: 0 18px 20px 18px;">
    <v-content>
      <v-container style="padding:0px">
        <!--유효성 검증을 위해 폼으로 컨트롤-->
        <v-form
          ref="form"
          v-model="validated"
          lazy-validation
          @keyup.native.enter="validate"
        >
          <!--렌탈사 코드-->
          <input
            v-model="account"
            class="inputst"
            type="text"
            :placeholder="'이메일을 입력하세요' | translate"
            :maxlength="250"
          >
          <input
            v-model="password"
            class="inputst"
            type="password"
            :placeholder="'비밀번호를 입력하세요' | translate"
            :maxlength="250"
          >
          <!--로그인버튼-->
          <v-btn
            block
            class="elevation-0 my-5"
            color="#0c98fe"
            tile
            height="50px"
            @click="validate"
          >
            {{ '로그인' | translate }}
          </v-btn>

          <div class="tn_idpw_search">
            <!--            <v-dialog-->
            <!--              v-model="idCard"-->
            <!--              max-width="600px"-->
            <!--              persistent-->
            <!--            >-->
            <!--              <template v-slot:activator="{ on }">-->
            <!--                <v-btn-->
            <!--                  :ripple="false"-->
            <!--                  text-->
            <!--                  v-on="on"-->
            <!--                >-->
            <!--                  {{ '아이디 찾기' | translate }}-->
            <!--                </v-btn>-->
            <!--              </template>-->
            <!--              <v-card>-->
            <!--                <div class="d-flex justify-space-between align-center px__15 pt-3">-->
            <!--                  <v-card-title-->
            <!--                    class="d-flex justify-center flex-grow-1 font__20 font-weight-bold pl__36"-->
            <!--                  >-->
            <!--                    {{ '아이디 찾기' | translate }}-->
            <!--                  </v-card-title>-->

            <!--                  <v-btn-->
            <!--                    :ripple="false"-->
            <!--                    class="button__Close"-->
            <!--                    text-->
            <!--                    @click="idCard = false"-->
            <!--                  >-->
            <!--                    <img src="@/assets/images/main/svg/button_Close.svg">-->
            <!--                  </v-btn>-->
            <!--                </div>-->


            <!--                <v-card-text>-->
            <!--                  <v-container style="height: 170px; padding: 0;">-->
            <!--                    <v-row v-if="!findedLoginId">-->
            <!--                      <v-col-->
            <!--                        cols="12"-->
            <!--                        style="height: 75px;"-->
            <!--                      >-->
            <!--                        <v-text-field-->
            <!--                          v-model="findKey.businessNo"-->
            <!--                          :label="'사업자번호를 입력하세요' | translate"-->
            <!--                          :maxlength="MAXLENGTH.COMPANY.BUSINESS_NO"-->
            <!--                          required-->
            <!--                        />-->
            <!--                      </v-col>-->
            <!--                      <v-col-->
            <!--                        cols="12"-->
            <!--                      >-->
            <!--                        <v-text-field-->
            <!--                          v-model="findKey.userMobileno"-->
            <!--                          :label="'핸드폰번호를 입력하세요' | translate"-->
            <!--                          :maxlength="MAXLENGTH.USER.MOBILENO"-->
            <!--                          required-->
            <!--                        />-->
            <!--                      </v-col>-->
            <!--                    </v-row>-->
            <!--                    <v-row>-->
            <!--                      <div>{{ findedLoginId }}</div>-->
            <!--                    </v-row>-->
            <!--                  </v-container>-->
            <!--                  <div-->
            <!--                    v-if="!findedLoginId"-->
            <!--                    class="text_alignL"-->
            <!--                  >-->
            <!--                    <small class="caption  font-weight-bold">{{ '회원님의 소중한 개인 정보 보호를 위하여' | translate-->
            <!--                                                             }}-->
            <!--                      {{ '본인 확인이 필요합니다.' | translate }}{{ '위의 정보를 입력하세요.' | translate }}</small>-->
            <!--                  </div>-->
            <!--                </v-card-text>-->
            <!--                <v-card-actions-->
            <!--                  class="ma-0 mt-2 pa-0"-->
            <!--                >-->
            <!--                  <div-->
            <!--                    v-if="!findedLoginId"-->
            <!--                    style="width: 100%;"-->
            <!--                  >-->
            <!--                    <v-btn-->
            <!--                      :ripple="false"-->
            <!--                      class="button__100per radius__None"-->
            <!--                      text-->
            <!--                      @click="onFindLoginId"-->
            <!--                    >-->
            <!--                      {{ '확인' | translate }}-->
            <!--                    </v-btn>-->
            <!--                  </div>-->
            <!--                  <v-btn-->
            <!--                    v-if="findedLoginId"-->
            <!--                    :ripple="false"-->
            <!--                    color="blue darken-1"-->
            <!--                    text-->
            <!--                    @click="idCard = false"-->
            <!--                  >-->
            <!--                    {{ '로그인창 이동' | translate }}-->
            <!--                  </v-btn>-->
            <!--                </v-card-actions>-->
            <!--              </v-card>-->
            <!--            </v-dialog>-->

            <!--            <v-dialog-->
            <!--              v-model="pwCard"-->
            <!--              max-width="600px"-->
            <!--              persistent-->
            <!--            >-->
            <!--              <template v-slot:activator="{ on }">-->
            <!--                <v-btn-->
            <!--                  :ripple="false"-->
            <!--                  text-->
            <!--                  v-on="on"-->
            <!--                >-->
            <!--                  {{ '비밀번호 찾기' | translate }}-->
            <!--                </v-btn>-->
            <!--              </template>-->
            <!--              <v-card>-->
            <!--                <div class="d-flex justify-space-between align-center px__15 pt-3">-->
            <!--                  <v-card-title-->
            <!--                    class="d-flex justify-center flex-grow-1 font__20 font-weight-bold pl__36"-->
            <!--                  >-->
            <!--                    {{ '비밀번호 찾기' | translate }}-->
            <!--                  </v-card-title>-->

            <!--                  <v-btn-->
            <!--                    :ripple="false"-->
            <!--                    class="button__Close"-->
            <!--                    text-->
            <!--                    @click="pwCard = false"-->
            <!--                  >-->
            <!--                    <img src="@/assets/images/main/svg/button_Close.svg">-->
            <!--                  </v-btn>-->
            <!--                </div>-->

            <!--                <v-card-text>-->
            <!--                  <v-container style="height: 247px; padding: 0;">-->
            <!--                    <v-row v-if="!findedPassword">-->
            <!--                      <v-col-->
            <!--                        cols="12"-->
            <!--                        style="height: 75px;"-->
            <!--                      >-->
            <!--                        <v-text-field-->
            <!--                          v-model="findKey.loginId"-->
            <!--                          :maxlength="MAXLENGTH.USER.LOGIN_ID"-->
            <!--                          :label="'아이디를 입력하세요' | translate"-->
            <!--                          required-->
            <!--                        />-->
            <!--                      </v-col>-->
            <!--                      <v-col-->
            <!--                        cols="12"-->
            <!--                        style="height: 75px;"-->
            <!--                      >-->
            <!--                        <v-text-field-->
            <!--                          v-model="findKey.userEmail"-->
            <!--                          :maxlength="MAXLENGTH.USER.EMAIL"-->
            <!--                          :label="'이메일을 입력하세요' | translate"-->
            <!--                          required-->
            <!--                        />-->
            <!--                      </v-col>-->
            <!--                      <v-col-->
            <!--                        cols="12"-->
            <!--                      >-->
            <!--                        <v-text-field-->
            <!--                          v-model="findKey.businessNo"-->
            <!--                          :maxlength="MAXLENGTH.COMPANY.BUSINESS_NO"-->
            <!--                          :label="'사업자번호를 입력하세요' | translate"-->
            <!--                          required-->
            <!--                        />-->
            <!--                      </v-col>-->
            <!--                    </v-row>-->
            <!--                    <v-row v-if="findedPassword">-->
            <!--                      <span>{{ '임시 비밀번호가 해당 이메일로 전송되었습니다.' | translate }}</span>-->
            <!--                    </v-row>-->
            <!--                  </v-container>-->
            <!--                  <div-->
            <!--                    v-if="!findedPassword"-->
            <!--                    class="text_alignL"-->
            <!--                  >-->
            <!--                    <small class="caption  font-weight-bold">{{ '회원님의 소중한 개인 정보 보호를 위하여' | translate-->
            <!--                                                             }}{{ '본인 확인이 필요합니다.' | translate }}-->
            <!--                      {{ '아래의 정보 기재를 하시면 가입자가 등록한' | translate }}{{ '이메일로 임시 비밀번호를 발송해 드립니다.' |-->
            <!--                        translate }}</small>-->
            <!--                  </div>-->
            <!--                </v-card-text>-->
            <!--                <v-card-actions-->
            <!--                  class="ma-0 mt-2 pa-0"-->
            <!--                >-->
            <!--                  <div-->
            <!--                    v-if="!findedPassword"-->
            <!--                    style="width: 100%;"-->
            <!--                  >-->
            <!--                    <v-btn-->
            <!--                      :ripple="false"-->
            <!--                      class="button__100per radius__None"-->
            <!--                      text-->
            <!--                      @click="onFindLoginPassword"-->
            <!--                    >-->
            <!--                      {{ "확인" | translate }}-->
            <!--                    </v-btn>-->
            <!--                  </div>-->
            <!--                  <v-btn-->
            <!--                    v-if="findedPassword"-->
            <!--                    @click="pwCard = false"-->
            <!--                  >-->
            <!--                    {{ '로그인창 이동' | translate }}-->
            <!--                  </v-btn>-->
            <!--                </v-card-actions>-->
            <!--              </v-card>-->
            <!--            </v-dialog>-->
            <v-btn
              :ripple="false"
              text
              @click="onClickSignup"
            >
              {{ '회원가입' | translate }}
            </v-btn>
          </div>
        </v-form>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
    import {PATH} from "@/constants";

    export default {
        name: "CommunityLogin",
        data() {
            return {
                /**
                 * 로그인 요청 처리중 여부
                 */
                signing: false,
                account: null,
                password: null,
                validated: false,
                dialog: false,
                idCard: false,
                pwCard: false,
                findKey: {
                    businessNo: "",
                    userMobileno: "",
                    loginId: "",
                    userEmail: ""
                },
                findedLoginId: null,
                findedPassword: false,

                rules: {
                    account: [
                        v => !!v || 'Account is required',
                        v => v && v.length >= 4 || 'Account must be over 4 length at least'
                    ],
                    password: [
                        v => !!v || 'Password is required',
                        v => v && v.length >= 4 || 'Password must be over 4 length at least'
                    ]
                }
            }
        },
        methods: {
            onClickSignup() {
                this.routerManager.push({name: 'Signup'});
            },

            validate() {
                if (!this.account || this.account.length < 4) return this.$snackbarError(this.$translate("올바르지 않은 아이디입니다."));
                if (!this.password || this.password.length < 4) return this.$snackbarError(this.$translate("올바르지 않은 비밀번호입니다."));
                this.signIn();
            },

            signIn: function () {
                this.signing = true;
                this.$userManager.communityLogin(this.account, this.password)
                    .then(() => {
                        this.signing = false;
                        // try {
                        //     this.notifyToNative();
                        // } catch (e) {
                        //
                        // }
                        this.onSignedIn();
                    })
                    .catch(e => {
                        this.$snackbarError(this.$translate('로그인 정보를 확인해주세요.'));
                    });
            },
            onSignedIn() {
                return this.$router.replace({name: 'LoginDivider'});
            },
            /**
             * 아이디 찾기
             */
            onFindLoginId() {
                this.$request(PATH.USER_FIND_ID)
                    .setObject(this.findKey)
                    .enqueue()
                    .then(res => {
                        this.findedLoginId = res
                    })
            },
            onFindLoginPassword() {

                this.$request(PATH.USER_FIND_PASSWORD)
                    .setObject({
                        userId: this.findKey.loginId,
                        userEmail: this.findKey.userEmail,
                        businessNo: this.findKey.businessNo
                    })
                    .enqueue()
                    .then(res => {
                        this.findedPassword = true;
                        alert(this.$translate('해당 e-mail로 임시비밀번호를 전송하였습니다.'));
                    })
                    .catch()

            },
        },
    }
</script>

<style scoped>
    >>> .v-tabs-bar {
        margin-bottom: 40px !important;
    }

    .tn_idpw_search {
        display: flex;
        position: relative;
        margin-top: 10px;
    }

    .tn_idpw_search button {
        position: relative;
        flex: 1;
        font-size: 14px;
    }

    .tn_idpw_search button:first-child:after {
        content: "";
        position: absolute;
        right: 2px;
        top: 12px;
        /*width: 1px;*/
        width: 0;
        height: 12px;
        background: #aaa;
    }


    .tn_idpw_search button:nth-child(4):after {
        content: "";
        position: absolute;
        right: -5px;
        top: 12px;
        width: 1px;
        height: 12px;
        background: #aaaaaa;
    }


    .tn_logo {
        display: block;
        width: 100%;
        height: 190px;
        background: url('/img/tn_logo.png') no-repeat center;
        background-size: 89px;
    }

    .elevation-0 {
        color: white;
    }

    .text_alignL {
        text-align: left;
    }

    .input_height {
        height: 75px;
    }

    >>> .v-dialog,
    >>> .v-card:not(.v-sheet--tile):not(.v-card--shaped) {
        border-radius: 0 !important;
    }

    >>> .v-application--wrap {
        min-height: initial;
    }

</style>
