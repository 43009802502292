<template>
  <div class="mbody">
    <welcome-header
      :title="'임대 업체 회원 가입' | translate"
      @click:back="onClickBack"
    />

    <div class="welcome__content">
      <div class="signup-header-container">
        <sign-up-step-header
          :current-step="stepControl.currentStep"
          :title="title"
          :steps="steps"
        />
      </div>

      <transition
        v-if="stepControl.currentStep == 0"
        name="slide-child"
      >
        <div class="child-component">
          <div>
            <rental-signup-step1
              :cache="cacheStep1"
              @onAgreementsChanged="onAgreementsChanged"
            />
          </div>
        </div>
      </transition>
      <transition name="slide-child">
        <div
          v-if="stepControl.currentStep == 1"
          class="child-component"
        >
          <div>
            <rental-signup-step2 />
          </div>
        </div>
      </transition>
      <transition name="slide-child">
        <div
          v-if="stepControl.currentStep == 2"
          class="child-component"
        >
          <div>
            <rental-signup-step3 />
          </div>
        </div>
      </transition>

      <sign-up-step-bottom
        :can-go-next="stepControl.canGoNext"
        :can-go-prev="stepControl.canGoPrev"
        :title-next="stepControl.buttonTitleNext"
        :title-prev="stepControl.buttonTitlePrev"
        @onNextClicked="onNextClicked"
        @onPrevClicked="onPrevClicked"
      />
    </div>
  </div>
</template>


<script>
    import WelcomeHeader from "@/views/welcome/part/WelcomeHeader";
    import WelcomeMixins from "@/views/welcome/part/WelcomeMixins";
    import SignUpStepHeader from "@/views/welcome/signup/part/SignUpStepHeader";
    import RentalSignupStep1 from "@/views/welcome/signup/rental/step/RentalSignupStep1";
    import RentalSignupStep2 from "@/views/welcome/signup/rental/step/RentalSignupStep2";
    import RentalSignupStep3 from "@/views/welcome/signup/rental/step/RentalSignupStep3";
    import SignUpStepBottom from "@/views/welcome/signup/part/SignuUpStepBottom";
    import {PATH} from "@/constants";

    const STEP1 = 0;
    const STEP2 = 1;
    const STEP3 = 2;

    export default {
        name: "RentalSignup",
        components: {
            SignUpStepBottom,
            RentalSignupStep3, RentalSignupStep2, RentalSignupStep1, SignUpStepHeader, WelcomeHeader
        },
        mixins: [WelcomeMixins],
        data() {
            return {
                // 헤더 제목
                title: this.$translate('회원가입 / 약관'),
                // 전체 단계 배열
                steps: [
                    {index: 0, title: this.$translate("1. 동의")},
                    {index: 1, title: this.$translate("2. 정보")},
                    {index: 2, title: this.$translate("3. 완료")}
                ],
                stepControl: {
                    // 현재 스텝
                    currentStep: 0,
                    // 현재 컴포넌트(현재 스텝)
                    currentComponent: null,
                    // 이전 화면으로 유저가 이동 가능
                    canGoPrev: false,
                    // 다음 화면으로 유저가 이동 가능
                    canGoNext: true,
                    buttonTitlePrev: this.$translate('이전'),
                    buttonTitleNext: this.$translate('다음')
                },
                cacheStep1: null,
                cacheStep2: null,
                // 회원 가입 요청 바디.
                requestBody: {
                    address: null,// '서울특별시 강서구 마곡중앙2로 5',
                    addressDetail: null,// '메트로비즈타워 5층 504호',
                    addressNewYn: null,// '',
                    buildingNo: null,// '',
                    businessKind: null,// '소프트웨어개발공급',
                    businessNo: null,// '123-12345-12',
                    businessType: null,// 'string',
                    ceoFullname: null,// '임국주',
                    companyName: null,// '틴텍CNC',
                    corporationNo: null,// '123-12345-12',
                    countryCode: null,// 'ko',
                    currency: null,// '원',
                    languageCode: null,// 'kr',
                    lat: null,// 32.3333,
                    lng: null,// 126.3333,
                    loginId: null,// '     ',
                    password: null,// '123456',
                    pushYn: null,// 'N',
                    smsYn: null,// 'N',
                    termsSignYn: null,// 'N',
                    privatePolicySignYn: null,// 'N',
                    locationSignYn: null,// 'N',
                    eventYn: null,// 'N',
                    timezoneCode: null,// 'Asis/Seoul',
                    userDept: null,// 'Tech.Group',
                    userEmail: null,// 'chilly.soda@gmail.com',
                    userFullname: null,// '정재위',
                    userMobileno: null,// '010-9962-3444',
                    zipCode: null,// '07391'
                    groupCodeId: '', // 그룹코드 아이디 (파트너/딜러)
                },
            };
        },
        watch: {
            'stepControl.currentStep': function (step, old) {
                switch (step) {
                    // 첫번째 창. 이전으로 돌아갈 수 없으며, 다음으로 진행만 가능.
                    case 0:
                        this.stepControl.currentComponent = RentalSignupStep1;
                        this.stepControl.canGoPrev = false;
                        this.stepControl.canGoNext = true;
                        this.stepControl.buttonTitleNext = this.$translate('다음');
                        break;
                    // 두번째 창. 이전으로 돌아갈 수 있으며, 다음이 아닌 가입으로 진행.
                    case 1:
                        this.stepControl.currentComponent = RentalSignupStep2;
                        this.stepControl.canGoPrev = true;
                        this.stepControl.canGoNext = true;
                        this.stepControl.buttonTitleNext = this.$translate('등록');
                        break;
                    case 2:
                        // 세번째 창. 이전으로 돌아갈 수 없으며, 완료로 종료만 가능.
                        this.stepControl.currentComponent = RentalSignupStep3;
                        this.stepControl.canGoPrev = false;
                        this.stepControl.canGoNext = true;
                        this.stepControl.buttonTitleNext = this.$translate('완료');
                        break;
                    default:
                        break;
                }
            },

        },
        created: function () {
            this.stepControl.currentStep = 0;
        },
        methods: {
            /**
             * 회원 가입 패널의 닫기 버튼이 눌리면 호출됩니다.
             */
            onCloseClicked() {
                console.log('SignUp:onCloseClicked');
                this.$emit('onCloseClicked');
            },
            onPrevClicked() {
                console.log('onPrevClicked.');
                // 이전으로 갈 수 있는 경우에만 스텝을 감소시킵니다.
                if (this.stepControl.currentStep > 0) {
                    this.stepControl.currentStep--;
                }
            },
            onNextClicked() {
                console.log('onNextClicked.')
                // 다음으로 갈 수 있는 경우에만 스텝을 증가시킵니다.
                switch (this.stepControl.currentStep) {
                    case STEP1:
                        // 필수 동의가 완료되었다면 다음으로 진행
                        if (this.isAgreeAll()) {
                            this.stepControl.currentStep++;
                        }
                        // 아니면 동의 요청
                        else {
                            alert(this.$translate('필수 동의 항목에 동의해 주세요.'));
                        }
                        break;
                    case STEP2:
                        this.onSignUpValidateClicked();
                        break;
                    case STEP3:
                        this.$emit('onSignUpCompleted');
                        break;
                }
            },
            /**
             * 동의값이 변경될 때 호출됩니다.
             * @param data 동의 여부 래퍼 객체
             */
            onAgreementsChanged: function (data) {
                console.log("onAgreementsChanged: data -> " + JSON.stringify(data, null, 4));
                this.cacheStep1 = data;
                this.requestBody.termsSignYn = data.serviceAgreement ? 'Y' : 'N';
                this.requestBody.privatePolicySignYn = data.privatePolicy ? 'Y' : 'N';
                this.requestBody.locationSignYn = data.locationInformation ? 'Y' : 'N';
                this.requestBody.eventYn = data.receiveMarketing ? 'Y' : 'N';
                console.log("onAgreementsChanged: requestBody -> " + JSON.stringify(this.requestBody, null, 4));
            },
            /**
             * 스텝2의 정보 입력이 변경될 때 호출됩니다. SignUpStep2.vue 내부에서 v-model 바인딩에 .lazy 지시자가 사용되었습니다.
             * @param data
             */
            onFormDataChanged: function (data) {
                console.log("onFormDataChanged: data -> " + JSON.stringify(data, null, 4));
                this.cacheStep2 = data;
                this.requestBody.userFullname = data.name;
                this.requestBody.userDept = data.userDept;
                this.requestBody.userEmail = data.userEmail;
                // this.requestBody.userMobileno = `${data.mobileSlot1}${data.mobileSlot2}${data.mobileSlot3}`;
                this.requestBody.userMobileno = data.userMobileno;
                this.requestBody.loginId = data.loginId;
                this.requestBody.password = data.password;
                this.requestBody.zipCode = data.zipCode;
                this.requestBody.address = data.address;
                this.requestBody.addressDetail = data.addressDetail;
                this.requestBody.lat = data.lat;
                this.requestBody.lng = data.lng;
                this.requestBody.addressNewYn = data.addressNewYn;
                this.requestBody.buildingNo = data.buildingNo;
                this.requestBody.pushYn = data.pushYn;
                this.requestBody.smsYn = data.smsYn;

                this.requestBody.companyName = data.companyName;
                this.requestBody.ceoFullname = data.ceoName;
                this.requestBody.businessType = data.businessType;
                this.requestBody.businessKind = data.businessKind;
                this.requestBody.businessNo = data.businessNo;
                // this.requestBody.businessNo = `${data.businessNoSlot1}${data.businessNoSlot2}${data.businessNoSlot3}`;
                // this.requestBody.corporationNo = data.corporationNo;
                this.requestBody.countryCode = data.countryCode;
                this.requestBody.languageCode = data.languageCode;
                this.requestBody.timezoneCode = data.timezoneCode;
                this.requestBody.currency = data.currency;

                console.log("onFormDataChanged: requestBody -> " + JSON.stringify(this.requestBody, null, 4));
            },
            onSignUpValidateClicked() {
                this.notifyEvent(ON_SIGN_UP_VALIDATE);
            },
            onValidationComplete() {
                if (!confirm(this.$translate('등록하시겠습니까?'))) {
                    return;
                }
                this.signUpConfirm();
            },
            signUpConfirm() {
                this.signUp()
            },
            signUp: function () {
                this.requestBody.locale = this.$languageAuth();
                this.$httpClient.post(PATH.USER_REGISTER, this.requestBody)
                    .then((response) => {
                        console.log(`${PATH.USER_REGISTER} => response : ${JSON.stringify(response, null, 4)}`)
                        this.registeredRentalCompanyId = response.rentalCompanyId;
                        this.onRegistrationCompleted();
                    })
                    .catch((e) => {
                        console.log(e)
                        if (e.data.code == '-1002') {
                            this.toastError(this.t("필수 항목을 작성하여 주세요"))
                        }
                    });
            },
            onDuplicatedId() {
                this.$request(PATH.MANAGE.CHECK_DUPILCATE)
                    .setObject(this.requestBody)
                    .enqueue()
                    .then(res => {
                        this.toastSuccess(this.t("사용 가능한 아이디 입니다."))
                    })

            },
            onRegistrationCompleted: function () {
                this.stepControl.currentStep = STEP3;
            },
            isAgreeAll: function () {
                if (this.cacheStep1 == null) return false;
                return this.cacheStep1.serviceAgreement
                    && this.cacheStep1.privatePolicy
                    && this.cacheStep1.locationInformation;
            },
            isFilledRequiredAll: function () {
                return this.requestBody.userFullname
                    && this.requestBody.loginId
                    && this.requestBody.password
                    && this.requestBody.userMobileno
                    && this.requestBody.userEmail
                    && this.requestBody.userDept
                    && this.requestBody.zipCode
                    && this.requestBody.address
                    && this.requestBody.addressDetail

                    && this.requestBody.companyName
                    && this.requestBody.ceoFullname
                    && this.requestBody.businessType
                    && this.requestBody.businessKind
                    && this.requestBody.businessNo
                    && this.requestBody.countryCode
                    && this.requestBody.languageCode
            }
        }
    }
</script>

<style scoped>
</style>