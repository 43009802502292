<template>
  <div class="signup-step__container">
    <div class="pad18">
      <div class="join-ok-message">
        {{ '회원가입 요청이 완료되었습니다.' | translate }}
      </div>

      <div class="join-ok-text">
        {{ '가입하신 이메일로 임시 비밀번호가 발송 되었습니다.' | translate }}
        {{ '임시 비밀번호로 로그인하여 주십시오.' | translate }}
      </div>
    </div>

    <step-fixed-bottom
      can-go-next
      :next-title="'완료' | translate"
      @click:next="onClickComplete"
    />
  </div>
</template>

<script>
    import StepFixedBottom from "@/views/welcome/part/StepFixedBottom";

    export default {
        name: "CommunitySignupStep3",
        components: {StepFixedBottom},
        methods: {
            onClickComplete() {
                this.$emit("complete");
            }
        }
    }
</script>

<style scoped>
</style>