<template>
  <div class="step-fixed-bottom__container">
    <div>
      <v-btn
        v-if="canGoPrev"
        :disabled="disablePrev"
        color="#2699FB"
        dark
        elevation="1"
        @click="$emit('click:prev')"
      >
        {{ prevTitle }}
      </v-btn>
    </div>
    <v-spacer />
    <div>
      <v-btn
        v-if="canGoNext"
        :disabled="disableNext"
        color="#2699FB"
        dark
        elevation="1"
        @click="$emit('click:next')"
      >
        {{ nextTitle }}
      </v-btn>
    </div>
  </div>
</template>

<script>
    export default {
        name: "StepFixedBottom",
        props: {
            prevTitle: {
                type: String,
                default: "Prev",
            },
            nextTitle: {
                type: String,
                default: "Next",
            },
            canGoPrev: {
                type: Boolean,
                default: false,
            },
            canGoNext: {
                type: Boolean,
                default: false,
            },
            disablePrev: {
                type: Boolean,
                default: false,
            },
            disableNext: {
                type: Boolean,
                default: false,
            },
        },
    }
</script>

<style scoped>
    .step-fixed-bottom__container {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 60px;
        background-color: #fff;
        display: flex;
        align-content: space-between;
        align-items: center;
        padding: 0 10px;
    }
</style>