<script>
    import {TERMS_OF_USE} from "@/constants/terms-of-use";
    import {SORT_USER} from "@/constants/etc-code";

    export default {
        name: "TermsOfUseMixins",
        data() {
            return {
                // 약관 전문
                statements: {
                    // 서비스 이용약관 전문
                    termServiceAgreement: '',
                    // 개인정보 취급 방침 전문
                    termPrivatePolicy: '',
                    // 위치정보 이용 약관 전문
                    termLocationAgreement: ''
                },
                // 이용약관 구분
                innerWhich: TERMS_OF_USE.SERVICE_AGREEMENT,
                sortUser: SORT_USER.RENTALCOMPANY,
                lang: "en",
                loadingCounter: 0,
                statementsOptions: [
                    { key: TERMS_OF_USE.SERVICE_AGREEMENT, value: this.$translate("서비스이용약관") },
                    { key: TERMS_OF_USE.PRIVATE_POLICY, value: this.$translate(" 개인정보처리방침") },
                    { key: TERMS_OF_USE.LOCATION_AGREEMENT, value: this.$translate("위치정보이용약관") },
                ],
                languageOptions: [
                    { key: "ko", value: this.$translate("한국어") },
                    { key: "en", value: this.$translate(" 영어") },
                    { key: "zh", value: this.$translate("중국어") },
                ],
            };
        },
        computed: {
            currentStatements() {
                let stmt = null;
                switch (this.innerWhich) {
                    case TERMS_OF_USE.SERVICE_AGREEMENT:
                        stmt = this.statements.termServiceAgreement;
                        break;
                    case TERMS_OF_USE.LOCATION_AGREEMENT:
                        stmt = this.statements.termLocationAgreement;
                        break;
                    case TERMS_OF_USE.PRIVATE_POLICY:
                        stmt = this.statements.termPrivatePolicy;
                        break;
                }
                return stmt;
            },

            title() {
                let t = "";
                switch (this.innerWhich) {
                    case TERMS_OF_USE.SERVICE_AGREEMENT:
                        t = this.$translate("서비스이용약관");
                        break;
                    case TERMS_OF_USE.PRIVATE_POLICY:
                        t = this.$translate("개인정보처리방침");
                        break;
                    case TERMS_OF_USE.LOCATION_AGREEMENT:
                        t = this.$translate("위치정보이용약관");
                        break;
                    default:
                        t = "No Title";
                        break;
                }
                return t;
            },
        },
        watch: {
            lang() {
                /** 유효한 언어로 초기화 */
                this.initLang(this.lang);
                /** 약관을 로드한다 */
                this.load();
            },
        },
        created() {
            /** 유효한 언어로 초기화 */
            this.initLang();
            /** 약관을 로드한다 */
            this.load();
        },
        methods: {
            /**
             * 현재 로그인한 계정의 또는 브라우저가 유효한 언어이면 해당하는 언어로 아니면 'en' 으로 설정
             */
            initLang(lang) {
                const langPart = lang || this.$languagePartAuth();

                switch (langPart) {
                    case "en":
                    case "ko":
                    case "zh":
                        this.lang = langPart;
                        break;
                    default:
                        this.lang = "en";
                        break;
                }
            },

            /**
             * 현재 선택된 언어와 구분(이용약관) 에 따른 데이터를 불러온다.
             */
            async load() {
                this.loadingCounter = 0;
                const langPart = this.lang;
                // 정적 정보를 불러옵니다.
                this.$httpClient.get(`/static/TermServiceAgreement-${langPart}.txt`)
                    .then((response) => {
                        this.statements.termServiceAgreement = response;
                        this.loadingCounter++;
                    })
                    .catch((e) => {
                        console.log(e);
                        this.loadingCounter++;
                    });
                this.$httpClient.get(`/static/TermPrivatePolicy-${langPart}.txt`)
                    .then((response) => {
                        this.statements.termPrivatePolicy = response;
                        this.loadingCounter++;
                    })
                    .catch((e) => {
                        console.log(e);
                        this.loadingCounter++;
                    });
                this.$httpClient.get(`/static/TermLocationAgreement-${langPart}.txt`)
                    .then((response) => {
                        this.statements.termLocationAgreement = response;
                        this.loadingCounter++;
                    })
                    .catch((e) => {
                        console.log(e);
                        this.loadingCounter++;
                    });
            },
        },
    }
</script>