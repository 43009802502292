<script>
    import ModalVuetifyUser from "@/assets/plugins/vuetify-modal/ModalVuetifyUser";
    import {MODAL_VUETIFY_NAMES} from "@/constants/vuetify-modal";
    import {PATH} from "@/constants";

    export default {
        name: "RentalLoginModalMixin",
        mixins: [ModalVuetifyUser],
        methods: {
            /**
             * 임대업체 유저 아이디 찾기 모달을 엽니다.
             */
            onClickFindId() {
                this.openModalVuetify(MODAL_VUETIFY_NAMES.FIND.RENTAL_USER_ID, {
                        persistent: true,
                        maxWidth: "600px",
                    }, {},
                    (data) => {
                    }, MODAL_VUETIFY_NAMES.FIND.RENTAL_USER_ID,
                );
            },

            /**
             * 임대업체 유저 비밀번호 찾기 모달을 엽니다.
             */
            onClickFindPassword() {
                this.openModalVuetify(MODAL_VUETIFY_NAMES.FIND.RENTAL_USER_PASSWORD, {
                        persistent: true,
                        maxWidth: "600px",
                    }, {},
                    (data) => {
                    }, MODAL_VUETIFY_NAMES.FIND.RENTAL_USER_PASSWORD,
                );
            },
        },
    }
</script>