<script>
    import {COUNTRIES} from "@/assets/resources/countries";
    import {MAXLENGTH, PATH} from "@/constants";
    import REGEX from "@/constants/regex";

    export default {
        name: "CommunitySignupStepFormData",
        data() {
            return {
                formData: undefined,
                formInfo: [
                    {
                        mutable: true,
                        required: true,
                        title: this.$translate('이메일'),
                        key: 'email',
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        options: {
                            type: "email",
                            maxlength: MAXLENGTH.COMMUNITY_USER.EMAIL,
                        },
                        rules: [
                            (data) => RegExp(REGEX.EMAIL).test(data.email) || this.$translate('올바른 이메일을 입력해주세요'),
                        ],
                    },
                    {
                        mutable: true,
                        required: true,
                        title: this.$translate('회사 이름'),
                        key: 'companyName',
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        options: {
                            maxlength: MAXLENGTH.COMMUNITY_USER.COMPANY_NAME,
                        },
                    },
                    {
                        mutable: true,
                        required: true,
                        title: this.$translate('담당자 이름'),
                        key: 'chargerName',
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        options: {
                            maxlength: MAXLENGTH.COMMUNITY_USER.CHARGER_NAME,
                        },
                    },
                    {
                        mutable: true,
                        required: true,
                        title: this.$translate('담당자 전화번호'),
                        key: 'telNumber',
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        options: {
                            type: "tel",
                            maxlength: MAXLENGTH.COMMUNITY_USER.TEL_NUMBER,
                        },
                    },
                    {
                        mutable: true,
                        required: true,
                        title: this.$translate('국가'),
                        key: 'countryCode',
                        read: (inEditMode, data) => {
                            return {
                                codeValue: data.countryCode,
                                codeKey: data.countryCode,
                            }
                        },
                        write: (data, v) => {
                            if (!v) return;
                            data.countryCode = v.codeKey;
                        },
                        formWriteType: this.$mps.form.types.OPTIONS_PICKER,
                        options: {
                            items: COUNTRIES,
                        },
                    },
                    {
                        mutable: true,
                        title: this.$translate('푸시 수신 설정'),
                        key: 'pushYn',
                        formWriteType: this.$mps.form.types.CHECKBOX,
                        placeholder: this.$translate("수신함"),
                    },
                    {
                        mutable: true,
                        title: this.$translate('이메일 수신 여부'),
                        key: 'emailYn',
                        formWriteType: this.$mps.form.types.CHECKBOX,
                        placeholder: this.$translate("수신함"),
                        options: {
                            explanation: this.$translate("TN-BOX에서 제공하는 유익한 소식을 이메일로 받으실 수 있습니다."),
                        },
                    },
                ],
            }
        },
        created() {
            this.initFormData();
        },
        methods: {
            initFormData() {
                this.$set(this, 'formData', {
                    email: null,
                    companyName: null,
                    chargerName: null,
                    telNumber: null,
                    countryCode: null,
                    push: false,
                    pushEmail: false,
                });
            },

            requestSave() {
                this.$request(PATH.COMMUNITY_USER.REGISTER)
                    .setObject(this.formData)
                    .enqueue()
                    .then(() => {
                        this.$emit("next");
                    })
                    .catch(() => {
                        this.$snackbarError(this.$translate("회원가입 도중 에러가 발생했습니다."));
                    });
            },

            async onSave() {
                const validated = await this.$refs.form.validate();
                console.log("validated:" + validated);
                if (!validated) return;
                this.requestSave();
            },
        },
    }
</script>