export const COUNTRIES = [
    {codeKey: 'KR', codeValue: 'Korea (South)'},
    {codeKey: 'CN', codeValue: 'China'},
    {codeKey: 'TW', codeValue: 'Taiwan'},
    {codeKey: 'US', codeValue: 'United States'},
    {codeKey: 'RU', codeValue: 'Russia'},
    // {codeKey: 'AD', codeValue: 'Andorra'},
    // {codeKey: 'AE', codeValue: 'United Arab Emirates'},
    // {codeKey: 'AF', codeValue: 'Afghanistan'},
    // {codeKey: 'AG', codeValue: 'Antigua & Barbuda'},
    // {codeKey: 'AI', codeValue: 'Anguilla'},
    // {codeKey: 'AL', codeValue: 'Albania'},
    // {codeKey: 'AM', codeValue: 'Armenia'},
    // {codeKey: 'AO', codeValue: 'Angola'},
    // {codeKey: 'AQ', codeValue: 'Antarctica'},
    // {codeKey: 'AR', codeValue: 'Argentina'},
    // {codeKey: 'AS', codeValue: 'Samoa (American)'},
    // {codeKey: 'AT', codeValue: 'Austria'},
    // {codeKey: 'AU', codeValue: 'Australia'},
    // {codeKey: 'AW', codeValue: 'Aruba'},
    // {codeKey: 'AX', codeValue: 'Åland Islands'},
    // {codeKey: 'AZ', codeValue: 'Azerbaijan'},
    // {codeKey: 'BA', codeValue: 'Bosnia & Herzegovina'},
    // {codeKey: 'BB', codeValue: 'Barbados'},
    // {codeKey: 'BD', codeValue: 'Bangladesh'},
    // {codeKey: 'BE', codeValue: 'Belgium'},
    // {codeKey: 'BF', codeValue: 'Burkina Faso'},
    // {codeKey: 'BG', codeValue: 'Bulgaria'},
    // {codeKey: 'BH', codeValue: 'Bahrain'},
    // {codeKey: 'BI', codeValue: 'Burundi'},
    // {codeKey: 'BJ', codeValue: 'Benin'},
    // {codeKey: 'BL', codeValue: 'St Barthelemy'},
    // {codeKey: 'BM', codeValue: 'Bermuda'},
    // {codeKey: 'BN', codeValue: 'Brunei'},
    // {codeKey: 'BO', codeValue: 'Bolivia'},
    // {codeKey: 'BQ', codeValue: 'Caribbean NL'},
    // {codeKey: 'BR', codeValue: 'Brazil'},
    // {codeKey: 'BS', codeValue: 'Bahamas'},
    // {codeKey: 'BT', codeValue: 'Bhutan'},
    // {codeKey: 'BV', codeValue: 'Bouvet Island'},
    // {codeKey: 'BW', codeValue: 'Botswana'},
    // {codeKey: 'BY', codeValue: 'Belarus'},
    // {codeKey: 'BZ', codeValue: 'Belize'},
    // {codeKey: 'CA', codeValue: 'Canada'},
    // {codeKey: 'CC', codeValue: 'Cocos (Keeling) Islands'},
    // {codeKey: 'CD', codeValue: 'Congo (Dem. Rep.)'},
    // {codeKey: 'CF', codeValue: 'Central African Rep.'},
    // {codeKey: 'CH', codeValue: 'Switzerland'},
    // {codeKey: 'CI', codeValue: 'Côte d\'Ivoire'},
    // {codeKey: 'CK', codeValue: 'Cook Islands'},
    // {codeKey: 'CL', codeValue: 'Chile'},
    // {codeKey: 'CM', codeValue: 'Cameroon'},
    // {codeKey: 'CO', codeValue: 'Colombia'},
    // {codeKey: 'CR', codeValue: 'Costa Rica'},
    // {codeKey: 'CU', codeValue: 'Cuba'},
    // {codeKey: 'CV', codeValue: 'Cape Verde'},
    // {codeKey: 'CW', codeValue: 'Curaçao'},
    // {codeKey: 'CX', codeValue: 'Christmas Island'},
    // {codeKey: 'CY', codeValue: 'Cyprus'},
    // {codeKey: 'CZ', codeValue: 'Czech Republic'},
    // {codeKey: 'DE', codeValue: 'Germany'},
    // {codeKey: 'DJ', codeValue: 'Djibouti'},
    // {codeKey: 'DK', codeValue: 'Denmark'},
    // {codeKey: 'DM', codeValue: 'Dominica'},
    // {codeKey: 'DO', codeValue: 'Dominican Republic'},
    // {codeKey: 'DZ', codeValue: 'Algeria'},
    // {codeKey: 'EC', codeValue: 'Ecuador'},
    // {codeKey: 'EE', codeValue: 'Estonia'},
    // {codeKey: 'EG', codeValue: 'Egypt'},
    // {codeKey: 'EH', codeValue: 'Western Sahara'},
    // {codeKey: 'ER', codeValue: 'Eritrea'},
    // {codeKey: 'ES', codeValue: 'Spain'},
    // {codeKey: 'ET', codeValue: 'Ethiopia'},
    // {codeKey: 'FI', codeValue: 'Finland'},
    // {codeKey: 'FJ', codeValue: 'Fiji'},
    // {codeKey: 'FK', codeValue: 'Falkland Islands'},
    // {codeKey: 'FM', codeValue: 'Micronesia'},
    // {codeKey: 'FO', codeValue: 'Faroe Islands'},
    // {codeKey: 'FR', codeValue: 'France'},
    // {codeKey: 'GA', codeValue: 'Gabon'},
    // {codeKey: 'GB', codeValue: 'Britain (UK)'},
    // {codeKey: 'GD', codeValue: 'Grenada'},
    // {codeKey: 'GE', codeValue: 'Georgia'},
    // {codeKey: 'GF', codeValue: 'French Guiana'},
    // {codeKey: 'GG', codeValue: 'Guernsey'},
    // {codeKey: 'GH', codeValue: 'Ghana'},
    // {codeKey: 'GI', codeValue: 'Gibraltar'},
    // {codeKey: 'GL', codeValue: 'Greenland'},
    // {codeKey: 'GM', codeValue: 'Gambia'},
    // {codeKey: 'GN', codeValue: 'Guinea'},
    // {codeKey: 'GP', codeValue: 'Guadeloupe'},
    // {codeKey: 'GQ', codeValue: 'Equatorial Guinea'},
    // {codeKey: 'GR', codeValue: 'Greece'},
    // {codeKey: 'GS', codeValue: 'South Georgia & the South Sandwich Islands'},
    // {codeKey: 'GT', codeValue: 'Guatemala'},
    // {codeKey: 'GU', codeValue: 'Guam'},
    // {codeKey: 'GW', codeValue: 'Guinea-Bissau'},
    // {codeKey: 'GY', codeValue: 'Guyana'},
    // {codeKey: 'HK', codeValue: 'Hong Kong'},
    // {codeKey: 'HM', codeValue: 'Heard Island & McDonald Islands'},
    // {codeKey: 'HN', codeValue: 'Honduras'},
    // {codeKey: 'HR', codeValue: 'Croatia'},
    // {codeKey: 'HT', codeValue: 'Haiti'},
    // {codeKey: 'HU', codeValue: 'Hungary'},
    // {codeKey: 'ID', codeValue: 'Indonesia'},
    // {codeKey: 'IE', codeValue: 'Ireland'},
    // {codeKey: 'IL', codeValue: 'Israel'},
    // {codeKey: 'IM', codeValue: 'Isle of Man'},
    // {codeKey: 'IN', codeValue: 'India'},
    // {codeKey: 'IO', codeValue: 'British Indian Ocean Territory'},
    // {codeKey: 'IQ', codeValue: 'Iraq'},
    // {codeKey: 'IR', codeValue: 'Iran'},
    // {codeKey: 'IS', codeValue: 'Iceland'},
    // {codeKey: 'IT', codeValue: 'Italy'},
    // {codeKey: 'JE', codeValue: 'Jersey'},
    // {codeKey: 'JM', codeValue: 'Jamaica'},
    // {codeKey: 'JO', codeValue: 'Jordan'},
    // {codeKey: 'JP', codeValue: 'Japan'},
    // {codeKey: 'KE', codeValue: 'Kenya'},
    // {codeKey: 'KG', codeValue: 'Kyrgyzstan'},
    // {codeKey: 'KH', codeValue: 'Cambodia'},
    // {codeKey: 'KI', codeValue: 'Kiribati'},
    // {codeKey: 'KM', codeValue: 'Comoros'},
    // {codeKey: 'KN', codeValue: 'St Kitts & Nevis'},
    // {codeKey: 'KP', codeValue: 'Korea (North)'},
    // {codeKey: 'KW', codeValue: 'Kuwait'},
    // {codeKey: 'KY', codeValue: 'Cayman Islands'},
    // {codeKey: 'KZ', codeValue: 'Kazakhstan'},
    // {codeKey: 'LA', codeValue: 'Laos'},
    // {codeKey: 'LB', codeValue: 'Lebanon'},
    // {codeKey: 'LC', codeValue: 'St Lucia'},
    // {codeKey: 'LI', codeValue: 'Liechtenstein'},
    // {codeKey: 'LK', codeValue: 'Sri Lanka'},
    // {codeKey: 'LR', codeValue: 'Liberia'},
    // {codeKey: 'LS', codeValue: 'Lesotho'},
    // {codeKey: 'LT', codeValue: 'Lithuania'},
    // {codeKey: 'LU', codeValue: 'Luxembourg'},
    // {codeKey: 'LV', codeValue: 'Latvia'},
    // {codeKey: 'LY', codeValue: 'Libya'},
    // {codeKey: 'MA', codeValue: 'Morocco'},
    // {codeKey: 'MC', codeValue: 'Monaco'},
    // {codeKey: 'MD', codeValue: 'Moldova'},
    // {codeKey: 'ME', codeValue: 'Montenegro'},
    // {codeKey: 'MF', codeValue: 'St Martin (French)'},
    // {codeKey: 'MG', codeValue: 'Madagascar'},
    // {codeKey: 'MH', codeValue: 'Marshall Islands'},
    // {codeKey: 'MK', codeValue: 'North Macedonia'},
    // {codeKey: 'ML', codeValue: 'Mali'},
    // {codeKey: 'MM', codeValue: 'Myanmar (Burma)'},
    // {codeKey: 'MN', codeValue: 'Mongolia'},
    // {codeKey: 'MO', codeValue: 'Macau'},
    // {codeKey: 'MP', codeValue: 'Northern Mariana Islands'},
    // {codeKey: 'MQ', codeValue: 'Martinique'},
    // {codeKey: 'MR', codeValue: 'Mauritania'},
    // {codeKey: 'MS', codeValue: 'Montserrat'},
    // {codeKey: 'MT', codeValue: 'Malta'},
    // {codeKey: 'MU', codeValue: 'Mauritius'},
    // {codeKey: 'MV', codeValue: 'Maldives'},
    // {codeKey: 'MW', codeValue: 'Malawi'},
    // {codeKey: 'MX', codeValue: 'Mexico'},
    // {codeKey: 'MY', codeValue: 'Malaysia'},
    // {codeKey: 'MZ', codeValue: 'Mozambique'},
    // {codeKey: 'NA', codeValue: 'Namibia'},
    // {codeKey: 'NC', codeValue: 'New Caledonia'},
    // {codeKey: 'NE', codeValue: 'Niger'},
    // {codeKey: 'NF', codeValue: 'Norfolk Island'},
    // {codeKey: 'NG', codeValue: 'Nigeria'},
    // {codeKey: 'NI', codeValue: 'Nicaragua'},
    // {codeKey: 'NL', codeValue: 'Netherlands'},
    // {codeKey: 'NO', codeValue: 'Norway'},
    // {codeKey: 'NP', codeValue: 'Nepal'},
    // {codeKey: 'NR', codeValue: 'Nauru'},
    // {codeKey: 'NU', codeValue: 'Niue'},
    // {codeKey: 'NZ', codeValue: 'New Zealand'},
    // {codeKey: 'OM', codeValue: 'Oman'},
    // {codeKey: 'PA', codeValue: 'Panama'},
    // {codeKey: 'PE', codeValue: 'Peru'},
    // {codeKey: 'PF', codeValue: 'French Polynesia'},
    // {codeKey: 'PG', codeValue: 'Papua New Guinea'},
    // {codeKey: 'PH', codeValue: 'Philippines'},
    // {codeKey: 'PK', codeValue: 'Pakistan'},
    // {codeKey: 'PL', codeValue: 'Poland'},
    // {codeKey: 'PM', codeValue: 'St Pierre & Miquelon'},
    // {codeKey: 'PN', codeValue: 'Pitcairn'},
    // {codeKey: 'PR', codeValue: 'Puerto Rico'},
    // {codeKey: 'PS', codeValue: 'Palestine'},
    // {codeKey: 'PT', codeValue: 'Portugal'},
    // {codeKey: 'PW', codeValue: 'Palau'},
    // {codeKey: 'PY', codeValue: 'Paraguay'},
    // {codeKey: 'QA', codeValue: 'Qatar'},
    // {codeKey: 'RE', codeValue: 'Réunion'},
    // {codeKey: 'RO', codeValue: 'Romania'},
    // {codeKey: 'RS', codeValue: 'Serbia'},
    // {codeKey: 'RU', codeValue: 'Russia'},
    // {codeKey: 'RW', codeValue: 'Rwanda'},
    // {codeKey: 'SA', codeValue: 'Saudi Arabia'},
    // {codeKey: 'SB', codeValue: 'Solomon Islands'},
    // {codeKey: 'SC', codeValue: 'Seychelles'},
    // {codeKey: 'SD', codeValue: 'Sudan'},
    // {codeKey: 'SE', codeValue: 'Sweden'},
    // {codeKey: 'SG', codeValue: 'Singapore'},
    // {codeKey: 'SH', codeValue: 'St Helena'},
    // {codeKey: 'SI', codeValue: 'Slovenia'},
    // {codeKey: 'SJ', codeValue: 'Svalbard & Jan Mayen'},
    // {codeKey: 'SK', codeValue: 'Slovakia'},
    // {codeKey: 'SL', codeValue: 'Sierra Leone'},
    // {codeKey: 'SM', codeValue: 'San Marino'},
    // {codeKey: 'SN', codeValue: 'Senegal'},
    // {codeKey: 'SO', codeValue: 'Somalia'},
    // {codeKey: 'SR', codeValue: 'SuricodeValue'},
    // {codeKey: 'SS', codeValue: 'South Sudan'},
    // {codeKey: 'ST', codeValue: 'Sao Tome & Principe'},
    // {codeKey: 'SV', codeValue: 'El Salvador'},
    // {codeKey: 'SX', codeValue: 'St Maarten (Dutch)'},
    // {codeKey: 'SY', codeValue: 'Syria'},
    // {codeKey: 'SZ', codeValue: 'Eswatini (Swaziland)'},
    // {codeKey: 'TC', codeValue: 'Turks & Caicos Is'},
    // {codeKey: 'TD', codeValue: 'Chad'},
    // {codeKey: 'TF', codeValue: 'French Southern & Antarctic Lands'},
    // {codeKey: 'TG', codeValue: 'Togo'},
    // {codeKey: 'TH', codeValue: 'Thailand'},
    // {codeKey: 'TJ', codeValue: 'Tajikistan'},
    // {codeKey: 'TK', codeValue: 'Tokelau'},
    // {codeKey: 'TL', codeValue: 'East Timor'},
    // {codeKey: 'TM', codeValue: 'Turkmenistan'},
    // {codeKey: 'TN', codeValue: 'Tunisia'},
    // {codeKey: 'TO', codeValue: 'Tonga'},
    // {codeKey: 'TR', codeValue: 'Turkey'},
    // {codeKey: 'TT', codeValue: 'Trinidad & Tobago'},
    // {codeKey: 'TV', codeValue: 'Tuvalu'},
    // {codeKey: 'TZ', codeValue: 'Tanzania'},
    // {codeKey: 'UA', codeValue: 'Ukraine'},
    // {codeKey: 'UG', codeValue: 'Uganda'},
    // {codeKey: 'UM', codeValue: 'US minor outlying islands'},
    // {codeKey: 'UY', codeValue: 'Uruguay'},
    // {codeKey: 'UZ', codeValue: 'Uzbekistan'},
    // {codeKey: 'VA', codeValue: 'Vatican City'},
    // {codeKey: 'VC', codeValue: 'St Vincent'},
    // {codeKey: 'VE', codeValue: 'Venezuela'},
    // {codeKey: 'VG', codeValue: 'Virgin Islands (UK)'},
    // {codeKey: 'VI', codeValue: 'Virgin Islands (US)'},
    // {codeKey: 'VN', codeValue: 'Vietnam'},
    // {codeKey: 'VU', codeValue: 'Vanuatu'},
    // {codeKey: 'WF', codeValue: 'Wallis & Futuna'},
    // {codeKey: 'WS', codeValue: 'Samoa (western)'},
    // {codeKey: 'YE', codeValue: 'Yemen'},
    // {codeKey: 'YT', codeValue: 'Mayotte'},
    // {codeKey: 'ZA', codeValue: 'South Africa'},
    // {codeKey: 'ZM', codeValue: 'Zambia'},
    // {codeKey: 'ZW', codeValue: 'Zimbabwe'},
];
