<template>
  <div
    class="indicator"
    :class="{'active':mounted}"
  >
    <label class="indicator-text">{{ title | translate }}</label>
  </div>
</template>

<script>
    export default {
        name: "SignUpStepIndicator",
        props: ['title', 'mounted'],
        data() {
            return {
                mount: this.mounted,
            }
        }
    }
</script>

<style scoped>
    .indicator {
        display: inline-block;
        width: 100px;
        height: 33px;
        line-height: 33px;
        border-radius: 33px;
        vertical-align: middle;
        background: #fff;
        border: 1px solid #ddd;
        color: #777;
        text-align: center;
        z-index: 1;
    }

    .indicator-text {
        /* alignment: center; */
        text-align: center;
    }

    .indicator-text.active,
    .active {
        background: #0c98fe;
        color: #fff;
        border: none;
    }
</style>