<template>
  <transition>
    <router-view class="fill-height" />
  </transition>
</template>

<script>
    export default {
        name: "WelcomeMain"
    }
</script>

<style scoped>

</style>