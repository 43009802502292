<template>
  <ul class="all_consent">
    <!--체크박스 : 전체 동의-->
    <li class="mgT20">
      <!-- v모델로 바인딩 하면 전체동의 상태에서 임의의 동의 1개가 해제되면 모두 해제됩니다. -->
      <input
        id="a1"
        v-model="agreeAll"
        type="checkbox"
        @change="onAgreeAllChanged($event)"
      >
      <label for="a1"><span v-text="titles.checkBoxAgreeAll" /></label>
    </li>
    <li>
      <ul class="termsand_conditions">
        <!---->
        <!--체크박스 : 서비스 이용약관-->
        <!---->
        <li class="mgT5">
          <input
            id="a2"
            v-model="agreements.serviceAgreement"
            type="checkbox"
          >
          <label for="a2"><span v-text="titles.checkBoxAgreeServiceAgreement" /></label>
        </li>
        <!--전문 : 서비스 이용약관-->
        <li class="mgT5">
          <textarea
            class="form-control"
            readonly
            v-text="statements.termServiceAgreement"
          />
        </li>
        <!---->
        <!--체크박스 : 개인정보 취급 방침-->
        <!---->
        <li class="mgT30">
          <input
            id="a3"
            v-model="agreements.privatePolicy"
            type="checkbox"
          >
          <label for="a3"><span v-text="titles.checkBoxAgreePrivatePolicy" /></label>
        </li>
        <!--전문 : 개인정보 취급 방침-->
        <li>
          <textarea
            class="form-control mgT5"
            readonly
            v-text="statements.termPrivatePolicy"
          />
        </li>
        <!---->
        <!--체크박스 : 위치정보 이용약관-->
        <!---->
        <li class="mgT30">
          <input
            id="a4"
            v-model="agreements.locationInformation"
            type="checkbox"
          >
          <label for="a4"><span v-text="titles.checkBoxAgreeLocationAgreement" /></label>
        </li>
        <!--전문 : 위치정보 이용약관-->
        <li>
          <textarea
            class="form-control mgT5"
            readonly
            v-text="statements.termLocationAgreement"
          />
        </li>
        <!---->
        <!--체크박스 : 마케팅 메일 이용약관-->
        <!---->
        <li class="mgT30">
          <input
            id="a5"
            v-model="agreements.receiveMarketing"
            type="checkbox"
          >
          <label for="a5"><span v-text="titles.checkBoxAgreeMarketingEmail" /></label>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
    export default {
        name: "RentalSignupStep1",
        components: {},
        props: ['cache'],
        data() {
            return {
                // 각 UI 요소 제목
                titles: {
                    checkBoxAgreeAll: this.$translate('전체동의'),
                    checkBoxAgreeServiceAgreement: this.$translate('이용약관 동의'),
                    checkBoxAgreePrivatePolicy: this.$translate('개인정보 수집 및 이용에 대한 안내'),
                    checkBoxAgreeLocationAgreement: this.$translate('위치정보 이용약관 동의'),
                    checkBoxAgreeMarketingEmail: this.$translate('이벤트 등 프로모션 알림 메일 수신(선택)')
                    // checkBoxAgreeAll: 'Agree all',
                    // checkBoxAgreeServiceAgreement: 'Service terms and conditions agreement',
                    // checkBoxAgreePrivatePolicy: 'Information on collection and use of a personal information',
                    // checkBoxAgreeLocationAgreement: 'Location terms and conditions agreement',
                    // checkBoxAgreeMarketingEmail: 'Receive promotional notifications such as a event.(optional)'
                },
                // 전체 동의
                agreeAll: false,
                // 각 동의 여부
                agreements: {
                    // 서비스 이용약관 동의
                    serviceAgreement: false,
                    // 개인정보 취급방침 동의
                    privatePolicy: false,
                    // 위치정보 이용약관 동의
                    locationInformation: false,
                    // 마케팅 이메일 수신 동의
                    receiveMarketing: false
                },
                // 약관 전문
                statements: {
                    // 서비스 이용약관 전문
                    termServiceAgreement: '',
                    // 개인정보 취급 방침 전문
                    termPrivatePolicy: '',
                    // 위치정보 이용 약관 전문
                    termLocationAgreement: ''
                },
            }
        },
        watch: {
            agreeAll: function (agreeAll, old) {
                console.log(`agreeAll : ${agreeAll}, old:${old}`);
            },
            agreements: {
                handler: function (agreements, old) {
                    // 모든 항목이 체크되었는지 검사합니다.
                    let agreeAll = this.agreements.serviceAgreement
                        && this.agreements.privatePolicy
                        && this.agreements.locationInformation
                        && this.agreements.receiveMarketing;
                    // 모든 항목이 체크되었다면 '전체동의'의 상태를 변경합니다.
                    if (this.agreeAll !== agreeAll) this.agreeAll = agreeAll;
                    this.$emit('onAgreementsChanged', agreements);
                },
                deep: true
            }
        },
        created() {
            // 캐시를 주입받으면 캐시를 데이터로 설정합니다.
            if (this.cache != null) this.agreements = this.cache;
            const langPart = this.$languagePart();
            // 정적 정보를 불러옵니다.
            this.$httpClient.get(`/static/TermServiceAgreement-${langPart}.txt`)
                .then((response) => {
                    this.statements.termServiceAgreement = response;
                })
                .catch((e) => {
                    console.log(e);
                });
            this.$httpClient.get(`/static/TermPrivatePolicy-${langPart}.txt`)
                .then((response) => {
                    this.statements.termPrivatePolicy = response;
                })
                .catch((e) => {
                    console.log(e);
                });
            this.$httpClient.get(`/static/TermLocationAgreement-${langPart}.txt`)
                .then((response) => {
                    this.statements.termLocationAgreement = response;
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        methods: {
            onAgreeAllChanged: function (event) {
                // event.target <- el
                // event.target.checked <- check value
                // 전체동의 상태가 변경되면 모든 동의상태를 변경해 줍니다.
                let checked = event.target.checked;
                this.agreeAll = checked;
                for (let agreementsKey in this.agreements) {
                    this.agreements[agreementsKey] = checked;
                }
            },
        }
    }
</script>

<style scoped>
    .all_consent > li > label > span {
        /*margin-left: 30px;*/
        font-size: 18px;
        font-weight: 600;
        position: relative;
        display: inline-block;
        vertical-align: middle;
    }

    /*.termsand_conditions span {
        padding: 0 0 0 30px;
    }*/

    .termsand_conditions {
        margin: 30px 0 10px 0;
    }

    input, select, textarea {
        background: #f8f8f8;
    }

    textarea {
        font-size: 12px;
        overflow-y: auto;
        height: 150px;
        backound-color: #fff;
        border: 1px solid #ddd !important;
    }

    /*.all_consent input[type=checkbox] + label:before {*/
    /*    content: "";*/
    /*    display: block;*/
    /*    width: 22px;*/
    /*    height: 22px;*/
    /*    position: absolute;*/
    /*    left: 0px;*/
    /*    bottom: 4px;*/
    /*    background-color: #fff;*/
    /*    border-radius: 2px;*/
    /*    box-shadow: inset 0px 1px 1px 0px rgba(0, 0, 0, 0), 0px 1px 0px 0px rgba(255, 255, 255, 0.3);*/
    /*    background: #fff;*/
    /*    border: 1px solid #ddd !important;*/
    /*    box-sizing: border-box;*/
    /*}*/

    /*.all_consent input[type=checkbox]:checked + label:before {*/
    /*    background: url(/img/icon_checked.png) -17px top no-repeat;*/
    /*}*/
</style>