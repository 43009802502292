<template>
  <v-app style="padding: 0 18px 20px 18px;">
    <v-content>
      <v-container style="padding:0px">
        <!--유효성 검증을 위해 폼으로 컨트롤-->
        <v-form
          ref="form"
          v-model="validated"
          lazy-validation
          @keyup.native.enter="validate"
        >
          <!--렌탈사 코드-->
          <input
            v-model="rentalCompanyCode"
            class="inputst"
            type="number"
            :placeholder="'회사코드를 입력하세요' | translate"
            min="10000"
            max="999999999"
            name="rental_login_rentalcompany_id"
          >
          <input
            v-model="account"
            class="inputst"
            type="text"
            :placeholder="'아이디를 입력하세요' | translate"
            :maxlength="MAXLENGTH.USER.LOGIN_ID"
            name="rental_login_id"
          >
          <input
            v-model="password"
            class="inputst"
            type="password"
            :placeholder="'비밀번호를 입력하세요' | translate"
            :maxlength="MAXLENGTH.USER.PASSWORD"
            name="rental_login_pw"
          >
          <!--로그인버튼-->
          <v-btn
            block
            class="elevation-0 my-5"
            color="#0c98fe"
            tile
            height="50px"
            @click="validate"
          >
            {{ '로그인' | translate }}
          </v-btn>

          <!-- 하단 부가 정보: 아이디 찾기, 비밀번호 찾기, 회원가입 -->
          <div class="tn_idpw_search">
            <v-btn
              :ripple="false"
              text
              @click="onClickFindId"
            >
              {{ '아이디 찾기' | translate }}
            </v-btn>

            <v-btn
              :ripple="false"
              text
              @click="onClickFindPassword"
            >
              {{ '비밀번호 찾기' | translate }}
            </v-btn>

            <v-btn
              :ripple="false"
              text
              @click="onClickSignup"
            >
              {{ '회원가입' | translate }}
            </v-btn>
          </div>
        </v-form>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
    import RentalLoginModalMixin from "@/views/welcome/login/mixins/RentalLoginModalMixin";

    export default {
        name: "RentalLogin",
        mixins: [RentalLoginModalMixin],
        data() {
            return {
                /**
                 * 로그인 요청 처리중 여부
                 */
                signing: false,
                rentalCompanyCode: null,
                account: null,
                password: null,
                validated: false,

                rules: {
                    rentalCompanyCode: [
                        v => !!v || 'Rental company code is required',
                        v => !isNaN(v) || 'Rental company code must be a number'
                    ],
                    account: [
                        v => !!v || 'Account is required',
                        v => v && v.length >= 4 || 'Account must be over 4 length at least'
                    ],
                    password: [
                        v => !!v || 'Password is required',
                        v => v && v.length >= 4 || 'Password must be over 4 length at least'
                    ]
                }
            }
        },
        methods: {
            onClickSignup() {
                this.routerManager.push({name: 'Signup'});
            },

            validate() {
                if (!this.rentalCompanyCode || isNaN(this.rentalCompanyCode)) return this.$snackbarError(this.$translate("회사코드를 입력하여 주십시오."));
                if (!this.account || this.account.length < 4) return this.$snackbarError(this.$translate("올바르지 않은 아이디입니다."));
                if (!this.password || this.password.length < 4) return this.$snackbarError(this.$translate("올바르지 않은 비밀번호입니다."));
                this.signIn();
            },

            signIn: function () {
                this.signing = true;
                this.$userManager.login(this.rentalCompanyCode, this.account, this.password)
                    .then(() => {
                        this.signing = false;
                        try {
                            this.notifyToNative();
                        } catch (e) {

                        }
                        this.routerManager.push({name: 'LoginDivider'});
                    })
                    .catch(e => {
                        if (e.data.code === -2051) {
                            return this.$snackbarError(this.$translate('로그인 정보를 확인해주세요.'));
                        } else if (e.data.code === -2053) {
                            return this.$snackbarError(this.$translate('로그인 정보를 확인해주세요.'));
                        }
                        this.$snackbarError(this.$translate("서버에서 에러가 발생하였습니다."));
                    });
            },
        },
    }
</script>

<style scoped>
    >>> .v-tabs-bar {
        margin-bottom: 40px !important;
    }

    .tn_idpw_search {
        display: flex;
        position: relative;
        margin-top: 10px;
    }

    .tn_idpw_search button {
        position: relative;
        flex: 1;
        font-size: 14px;
    }

    .tn_idpw_search button:first-child:after,
    .tn_idpw_search button:nth-child(2):after {
        content: "";
        position: absolute;
        right: 2px;
        top: 12px;
        width: 1px;
        height: 12px;
        background: #aaa;
    }


    .tn_idpw_search button:nth-child(4):after {
        content: "";
        position: absolute;
        right: -5px;
        top: 12px;
        width: 1px;
        height: 12px;
        background: #aaaaaa;
    }


    .tn_logo {
        display: block;
        width: 100%;
        height: 190px;
        background: url('/img/tn_logo.png') no-repeat center;
        background-size: 89px;
    }

    .elevation-0 {
        color: white;
    }

    .text_alignL {
        text-align: left;
    }

    .input_height {
        height: 75px;
    }

    >>> .v-dialog,
    >>> .v-card:not(.v-sheet--tile):not(.v-card--shaped) {
        border-radius: 0 !important;
    }

    >>> .v-application--wrap {
        min-height: initial;
    }

</style>
