<template>
  <div />
</template>

<script>
    export default {
        name: "RentalSignupStep3"
    }
</script>

<style scoped>

</style>