<template>
  <ul class="buttons">
    <li
      v-show="canGoPrev"
      class="prev_step"
      @click="onPrevClicked"
    >
      {{ titlePrev }}
    </li>
    <li
      v-show="canGoNext"
      class="next_step"
      @click="onNextClicked"
    >
      {{ titleNext }}
    </li>
  </ul>
</template>

<script>
    export default {
        name: "SignUpStepBottom",
        props: [
            'canGoPrev',
            'canGoNext',
            'titlePrev',
            'titleNext'
        ],
        methods: {
            // 이전 버튼 클릭 됨.
            onPrevClicked: function () {
                this.$emit('onPrevClicked');
            },
            // 다음 버튼 클릭 됨.
            onNextClicked: function () {
                this.$emit('onNextClicked');
            }
        }
    }
</script>

<style scoped>
    .buttons {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between;
        width: 100%;
        bottom: 0;
        position: relative;
    }

    .prev_step,
    .next_step {
        position: absolute;
        /* bottom: 20px; */
        background: #0c98fe;
        padding: 8px 20px;
        color: #fff;
        cursor: pointer;
        border-radius: 4px;
    }

    .prev_step {
        left: 0px;
    }

    .next_step {
        right: 0px;
    }

    .step_button li:last-child {
        background: #0c98fe;
        color: #fff;
        cursor: pointer;
    }
</style>