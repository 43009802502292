<template>
  <!--이용약관_start-->
  <div class="mbody">
    <welcome-header
      :title="title"
      @click:back="routerManager.back()"
    />
    <div class="welcome__content">
      <div class="policy_cont">
        <div class="tabarea">
          <div class="seltcont">
            <div class="seltbox">
              <select
                v-model="innerWhich"
                class="select__custom"
              >
                <option
                  v-for="(statement, index) in statementsOptions"
                  :key="statement.key"
                  :value="statement.key"
                >
                  {{ statement.value }}
                </option>
              </select>
              <div
                class="select__arrow"
              />
            </div>
            <div class="selspacer" />
            <div class="seltbox">
              <select
                v-model="lang"
                class="select__custom"
              >
                <option
                  v-for="(language, index) in languageOptions"
                  :key="language.key"
                  :value="language.key"
                >
                  {{ language.value }}
                </option>
              </select>
              <div class="select__arrow" />
            </div>
          </div>
        </div>

        <div class="policy_txt">
          {{ currentStatements }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import WelcomeHeader from "@/views/welcome/part/WelcomeHeader";
    import TermsOfUseMixins from "@/views/welcome/termsofuse/mixins/TermsOfUseMixins";

    export default {
        name: "TermsOfUse",
        components: {WelcomeHeader},
        mixins: [TermsOfUseMixins],
        props: {
            which: undefined,
        },
        mounted() {
            if (this.which) {
                this.innerWhich = this.which;
            }
        },
        created() {
            if (this.which) {
                this.innerWhich = this.which;
            }
        },
    }
</script>

<style scoped>
    /*이용약관_start*/
    .policy_cont {
        padding: 18px 18px;
    }

    .tabarea {
        width: 100%;
        text-align: left;
    }


    .policy_cont h2 {
        text-align: left;
        font-size: 1.125rem;
        margin-bottom: 20px;
    }

    .policy_cont .policy_txt {
        font-size: 0.8125rem;
        color: #202020;
        text-align: left;
        padding: 10px;
        white-space: pre-line;
        line-height: 24px;
    }

    .seltcont {
        display: flex;
    }

    .seltcont .seltbox {
        width: 48%;
        position: relative;
    }

    .seltcont .selspacer {
        width: 4%;
    }

    .seltcont .seltbox select {
        height: 40px;
        line-height: 40px;
        border: 1px solid #ddd;
        width: 100%;
        padding: 0 6px;
        font-size: 0.875rem;
        letter-spacing: -0.5px;
    }

    /*이용약관_end*/
</style>