<template>
  <div>
    <welcome-header
      :title="'로그인' | translate"
      @click:back="onClickBack"
    />

    <div class="welcome__content">
      <div class="logimg">
        <img src="@/assets/images/main/v_logo.png">
      </div>

      <v-tabs
        v-model="tab"
        background-color="white"
        color="black"
        hide-slider
      >
        <v-tab>
          {{ '임대 업체 회원' | translate }}
        </v-tab>
        <v-tab>
          {{ '일반 회원' | translate }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <!-- 임대업체 회원-->
        <v-tab-item>
          <rental-login />
        </v-tab-item>

        <!-- 일반 회원-->
        <v-tab-item>
          <community-login />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>

    import RentalLogin from "@/views/welcome/login/RentalLogin";
    import CommunityLogin from "@/views/welcome/login/CommunityLogin";
    import WelcomeMixins from "@/views/welcome/part/WelcomeMixins";
    import WelcomeHeader from "@/views/welcome/part/WelcomeHeader";

    export default {
        name: "NewLogin",
        components: {WelcomeHeader, CommunityLogin, RentalLogin},
        mixins: [WelcomeMixins],
        data() {
            return {
                tab: null,
                tabs: [],
            }
        },
        methods: {
            onClickBack() {
                this.routerManager.back();
            }
        },
    }
</script>

<style scoped>
    header {
        height: 56px;
        border-bottom: 1px solid #ddd;
    }

    header .headtit {
        font-size: 1.25rem;
        line-height: 56px;
        font-weight: 700;
        padding-left: 18px;
        text-align: left;
    }

    header button {
        position: absolute;
        top: 15px;
        right: 18px;
    }

    .logimg {
        padding-top: 30px;
    }

    .logimg img {
        width: 70px;
    }

    .v-tabs {
        margin: 70px 0 40px 0;
        padding: 0 18px;
    }

    .v-tab {
        width: 50%;
        height: 40px;
        line-height: 40px;
        color: #7F7F7F;
        border: 1px solid #A0A0A0;
        font-size: 0.875rem;
        font-weight: 600;
        max-width: 100%;
    }

    .v-tab--active {
        background-color: #434343;
        color: #fff;
        opacity: 1;
    }


</style>









