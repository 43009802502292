<template>
  <div>
    <welcome-header
      :title="'회원가입' | translate"
      @click:back="onClickBack"
    />

    <div class="welcome__content">
      <div class="joinbbox">
        <a
          href="javascript:void(0)"
          class="joinbox"
        >
          <div class="boxtop">
            {{ '임대 업체 회원 가입' | translate }}
            <!--          <span><img src="@/assets/images/main/svg/button__Previous.svg"></span>-->
          </div>
          <!--        <span>프린터 렌탈 서비스로<strong>실시간 모니터링 시스템과 거래처  통합관리 솔루션</strong>을 이용할 수 있습니다.</span>-->
          <span>{{ '준비중입니다' | translate }}</span>
        </a>
        <a
          href="javascript:void(0)"
          @click="onClickCommunitySignup"
        >
          <div class="boxtop">
            {{ '일반회원 가입' | translate }}
            <span><img src="@/assets/images/main/svg/button__Previous.svg"></span>
          </div>
          <span>{{ 'TN-BOX의 다양하고 새로운 소식을 받을 수 있습니다' | translate }}</span>
        </a>
      </div>
      <div class="botimg">
        <img src="@/assets/images/main/o_icon.png">
      </div>
    </div>
  </div>
</template>

<script>
    import WelcomeHeader from "@/views/welcome/part/WelcomeHeader";
    import WelcomeMixins from "@/views/welcome/part/WelcomeMixins";

    export default {
        name: "Signup",
        components: {WelcomeHeader},
        mixins: [WelcomeMixins],
        methods: {
            onClickRentalSignup() {
                this.routerManager.push({name: 'RentalSignup'});
            },

            onClickCommunitySignup() {
                this.routerManager.push({name: 'CommunitySignup'});
            },

        },
    }
</script>

<style scoped>
    header {
        height: 56px;
        border-bottom: 1px solid #ddd;
    }

    header .headtit {
        font-size: 1.25rem;
        line-height: 56px;
        font-weight: 700;
        padding-left: 18px;
        text-align: left;
    }

    header button {
        position: absolute;
        top: 15px;
        right: 18px;
    }

    .joinbbox {
        margin-top: 23px;
        padding: 0 16px;
    }

    .joinbbox a {
        border: 1px solid #ddd;
        padding: 20px 16px;

        display: block;
        text-align: left;
        color: #000000;
        text-decoration: none;
        margin-top: 24px;
    }

    .joinbbox a .boxtop {
        font-size: 1.25rem;
        font-weight: 600;
        position: relative;
        padding-bottom: 12px;
    }

    .joinbbox a .boxtop span {
        position: absolute;
        top: 4px;
        right: 6px;
    }

    .joinbbox a .boxtop span img {
        transform: rotate(90deg);
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        filter: "FlipH";
        -ms-filter: "FlipH";
    }

    .botimg {
        padding-top: 80px;
    }

    .botimg img {
        width: 70px;
    }

</style>

<style>
    .signup-step__container {
        position: relative;
        text-align: left;
        padding: 0 0 0 0;
        height: 100%;
        overflow: hidden;
    }

    .signup-step__inner-container {
        padding: 0 10px 0 10px;
        overflow-y: auto;
        height: calc(100% - 60px);
    }

    .signup-step__tabs-items {
        height: calc(100% - 56px);
    }

    .signup-step__tabs-items .v-window__container {
        height: 100% !important;
    }

    .join-ok-message {
        font-weight: bold;
        margin-bottom: 1.2rem;
    }

    .mbody {
        position: inherit !important;
        overflow: hidden;
    }

    .signup-header-container {
        padding: 0 8px;
    }

    .signup-header-container h2 {
        text-align: left;
        padding-top: 30px;
    }


    /**
     * 폼 레이블 디자인 일괄 수정
     */
    .signup-step__container .mps-form-label__wrapper {
        text-align: right !important;
        padding-left: 8px !important;
        /*background:#f8f8f8;*/
        font-weight: bold;
    }

    /**
     * 폼 레이블 디자인 일괄 수정
     */

    /**
      * 폼 레이블 디자인 일괄 수정
      */
    .signup-step__container .mps-form-field__wrapper {
        background-color: #ffffff;
    }

    .signup-step__inner-container {
        padding: 0 18px 0 8px;
    }


    .signup-step__inner-container ul {
        list-style: none;
        padding-left: 0px;
    }

    .signup-step__inner-container ul li input {
        width: 22px;
        height: 22px;
        border: 1px solid #DDDDDD !important;
        vertical-align: top;
        margin-right: 12px;
    }

    .pdB10 {
        padding-bottom: 10px;
    }

    .mgB30 {
        margin-bottom: 30px;
    }

    .bort1 {
        border-top: 1px solid #ddd;
    }

    .pad18 {
        padding: 18px
    }
</style>