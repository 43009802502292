<template>
  <div style="height: 88px;">
    <!--제목 영역-->
    <h2
      class="title"
      style="padding-left: 8px;"
      v-text="title"
    />

    <!--스텝 캐러셀 영역-->
    <div class="indicator-group">
      <indicator
        v-for="(step, index) in steps"
        :key="index"
        :mounted="isMounted(step.index)"
        v-text="step.title"
      />
    </div>
  </div>
</template>

<script>
    import SignUpStepIndicator from "./SignUpStepIndicator";

    export default {
        name: "SignUpStepHeader",
        components: {indicator: SignUpStepIndicator},
        props: {
            currentStep: undefined,
            title: undefined,
            steps: {
                type: Array,
                default: () => [],
            },
        },
        data() {
            return {
                // 현재 단계
                currentPosition: 0,
            }
        },
        methods: {
            onCloseClicked: function () {
                console.log('SignUpStepHeader:onCloseClicked');
                this.$emit('onCloseClicked');
            },
            isMounted: function (step) {
                return step === this.currentStep;
            }
        }
    }
</script>

<style scoped>
    .title {
        font-size: 19px;
        font-weight: bold;
      text-align: left;
      padding:30px 0 0 18px;
    }

    .indicator-group {
        display: flex;
        justify-content: space-between;
        margin: 30px 0 20px 0;
        width: 100%;
        position: relative;
    }

    .indicator-group::after {
        content: '';
        width: 100%;
        border-bottom: 1px solid #ddd;
        position: absolute;
        bottom: 15px;
        right: 0;
        left: 0;
        z-index: 0
    }

    /* 활성화 되었을 때 색상 */
    /* 활성화 될 때 indicator active 이런식으로 추가해 주세요 */
    .active {
        background: #0c98fe;
        color: #fff;
        border: none;
    }

    button {
        position: relative;
        font-family: "Noto Sans KR", sans-serif;
        color: #000;
        vertical-align: middle;
        border: 0 none;
        border-radius: 0;
        box-sizing: border-box;
        background-color: transparent;
        cursor: pointer;
        outline: 0;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-appearance: button;
    }


    .button-close-sign-up {
        position: absolute;
        display: inline-block;
        right: 33px;
        top: 24px;
        width: 25px;
        height: 25px;
        background: url(/img/btn_cancle.png) center center no-repeat;
        background-size: 17px;
        font-family: "Noto Sans KR", sans-serif;
        color: #000;
        border: 0 none;
        border-radius: 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        cursor: pointer;
        outline: 0;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-appearance: button;
    }

    /*.indicator:after {*/
    /*    content: '';*/
    /*    display: inline-block;*/
    /*    width: 70%;*/
    /*  */
    /*    height: 30px;*/
    /*    border-bottom: 1px solid #ddd;*/
    /*    position: relative;*/
    /*    top: -48px;*/
    /*    right: -85px;*/
    /*}*/

    .indicator:nth-child(2):after {
        right: -85px;
        width: 73%;
    }

    .indicator:last-child:after {
        border-bottom: none;
    }
</style>