<template>
  <div class="signup-step__container">
    <div class="signup-step__inner-container">
      <v-layout
        class="fill-height"
      >
        <v-container
          ref="container"
          class="overflow-y-auto pa-0 ma-0 mx-auto bort1"
          fluid
        >
          <m-form
            ref="form"
            :edit="true"
            :form-data.sync="formData"
            :form-info="formInfo"
            class="overflow-y-auto overflow-x-hidden"
            form-provider="mps"
          />
        </v-container>
      </v-layout>
    </div>

    <step-fixed-bottom
      can-go-prev
      :prev-title="'이전' | translate"
      can-go-next
      :next-title="'다음' | translate"
      @click:prev="onClickPrev"
      @click:next="onClickNext"
    />
  </div>
</template>

<script>
    import StepFixedBottom from "@/views/welcome/part/StepFixedBottom";
    import CommunitySignupStepFormData from "@/views/welcome/signup/community/step/mixins/CommunitySignupStepFormData";
    import MForm from "@/assets/plugins/mps-form/MForm";

    export default {
        name: "CommunitySignupStep2",
        components: {StepFixedBottom, MForm},
        mixins: [CommunitySignupStepFormData],
        data() {
            return {}
        },
        methods: {
            onClickNext() {
                this.onSave();
            },

            onClickPrev() {
                this.$emit("prev");
            }
        }
    }
</script>

<style scoped>

</style>