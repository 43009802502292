<template>
  <div class="mbody">
    <welcome-header
      :title="'일반회원 가입' | translate"
      @click:back="onClickBack"
    />

    <div class="welcome__content">
      <div class="signup-header__cntainer">
        <sign-up-step-header
          :current-step="step"
          :title="title"
          :steps="steps"
        />
      </div>

      <div class="signup-content__container">
        <v-tabs-items
          v-model="step"
          class="signup-step__tabs-items"
          touchless
        >
          <v-tab-item
            :key="0"
            class="fill-height"
          >
            <community-signup-step1 @next="onClickNext" />
          </v-tab-item>
          <v-tab-item
            :key="1"
            class="fill-height"
          >
            <community-signup-step2
              @next="onClickNext"
              @prev="onClickPrev"
            />
          </v-tab-item>
          <v-tab-item
            :key="2"
            class="fill-height"
          >
            <community-signup-step3
              @complete="onClickComplete"
            />
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
  </div>
</template>


<script>
    import WelcomeHeader from "@/views/welcome/part/WelcomeHeader";
    import WelcomeMixins from "@/views/welcome/part/WelcomeMixins";
    import SignUpStepHeader from "@/views/welcome/signup/part/SignUpStepHeader";
    import CommunitySignupStep1 from "@/views/welcome/signup/community/step/CommunitySignupStep1";
    import CommunitySignupStep2 from "@/views/welcome/signup/community/step/CommunitySignupStep2";
    import CommunitySignupStep3 from "@/views/welcome/signup/community/step/CommunitySignupStep3";

    export default {
        name: "CommunitySignup",
        components: {
            CommunitySignupStep3,
            CommunitySignupStep2,
            CommunitySignupStep1,
            SignUpStepHeader, WelcomeHeader
        },
        mixins: [WelcomeMixins],
        data() {
            return {
                // 헤더 제목
                title: this.$translate('회원가입 / 약관'),
                // 전체 단계 배열
                steps: [
                    {index: 0, title: this.$translate("1. 동의")},
                    {index: 1, title: this.$translate("2. 정보")},
                    {index: 2, title: this.$translate("3. 완료")}
                ],
                step: 0,
            };
        },
        computed: {},
        watch: {},
        created: function () {
        },
        methods: {
            onClickNext() {
                if (this.step >= 0 && this.step <= 1)
                    this.step++;
            },

            onClickPrev() {
                if (this.step > 0 && this.step <= 1)
                    this.step--;
            },

            /**
             * 회원가입이 완료되었습니다.
             */
            onClickComplete() {
                this.$destroy(); // 회원가입이 완료되면 Keep Alive 로 캐시된 정보를 삭제합니다.
                window.location.href = '/';
            },
        },
    }
</script>

<style scoped>
    .mbody {
        position: inherit !important;
    }

    .signup-header__cntainer {
        padding: 0 8px;
    }

    .signup-content__container {
        width: 100%;
        height: calc(100vh - 88px);
        padding-top: 70px;
    }
</style>